<template>
  <div>
    <div class="row">
      <div class="col">
        <b-table
          show-empty
          :busy="loading"
          :fields="campos"
          :items="categorias"
          striped
          hover
        >
          <template #empty>
            <div class="text-center">
              Nenhuma categoria encontrada. 😕
            </div>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="{item}">
            <div class="d-flex justify-content-end">
              <b-btn variant="warning" @click="editar(item.id)" class="mr-3">
                <i class="fas fa-edit"></i> Editar
              </b-btn>
              <b-btn variant="primary" @click="excluir(item.id)">
                <i class="fas fa-trash"></i> Excluir</b-btn
              >
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categorias: [Array],
  },
  data() {
    return {
      campos: [
        { key: "nome", label: "Categoria" },
        { key: "actions", label: "", class: "float-rigth" },
      ],
    };
  },
  methods: {
    editar(id) {
      this.$emit("editar", id);
    },
    excluir(id) {
      this.$emit("excluir", id);
    },
  },
};
</script>

<style></style>
