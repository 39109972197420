<template>
  <div>
    <b-form @submit.prevent.stop="salvar">
      <div class="row">
        <div class="col">
          <b-form-group>
            <label>Nome</label>
            <b-form-input
              v-model="categoria.nome"
              :state="!!categoria.nome"
              placeholder="Nome"
            ></b-form-input>
            <b-form-invalid-feedback>
                O nome da categoria é obrigatório!
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="bottom-actions">
        <hr />
        <b-btn :disabled="loading" class="mr-3" variant="primary" type="submit">
          <i class="fas fa-save" v-if="!loading" ></i> <b-spinner v-if="loading" small ></b-spinner> Salvar</b-btn
        >
        <b-btn variant="warning" @click="$emit('close')">
          <i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import ContasPagarCategoriasLib from "@/libs/ContasPagarCategoriasLib.js";
export default {
    props:{
        categoria_id: [Number, String]
    },
  data() {
    return {
        loading: false,
      categoria: {
        nome: "",
      },
    };
  },
  methods: {
    async salvar() {
        this.loading =true
      try {
        if (this.validar()) {
          const result = await ContasPagarCategoriasLib.store(this.categoria);
          if (result.status == 201 || result.status == 200) {
            this.showToast("success", "Solicitação feita com sucesso!");
            this.$emit("salvou");
          }
        } else {
          this.$swal(
            "Erro",
            `Por favor, preencha os campos obrigatórios!`,
            "error"
          );
        }
      } catch (error) {
        console.error("Erro ao salvar categoria", error);
         this.showToast("error", error);
      }
      this.loading = false
    },
    async carregarCategoria(){
        this.categoria = await ContasPagarCategoriasLib.get(this.categoria_id)
    },
    validar() {
      let validou = true;
      if (!this.categoria.nome) {
        validou = false;
      }

      return validou;
    },
  },
  async mounted(){
      if (this.categoria_id) {
          await this.carregarCategoria()
      }
  }
};
</script>

<style></style>
