<template>
  <div>
    <barra-crud :botaoNovo="true" :onNovo="novo" :onFiltrar="filtrar" />
    <contas-pagar-categorias-table :categorias="categorias" @excluir="excluir($event)" @editar="editar($event)" />
    <b-modal
      id="modal-cp-categorias"
      content-class="modalDireito"
      no-fade
      hide-footer
    >
      <template #modal-title>
        <img src="../../assets/img/icones/menu/exchange_40px_dark.png" alt="" />
        Cadastrar / Editar Categoria
      </template>
      <contas-pagar-categorias-form :categoria_id="id_edit" @close="close" @salvou="onSalvar" />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud";
import ContasPagarCategoriasLib from "../../libs/ContasPagarCategoriasLib.js";
import ContasPagarCategoriasForm from "@/components/ContasPagarCategorias/ContasPagarCategoriasForm.vue";
import ContasPagarCategoriasTable from "@/components/ContasPagarCategorias/ContasPagarCategoriasTable.vue";
export default {
  components: {
    BarraCrud,
    ContasPagarCategoriasForm,
    ContasPagarCategoriasTable,
  },
  data() {
    return {
      categorias: [],
      loading: false,
      id_edit: null
    };
  },
  methods: {
    novo() {
      //   this.limpar();
      this.id_edit = null
      this.$bvModal.show("modal-cp-categorias");
    },
    filtrar(filtro) {
      this.filtro = filtro;
    },
    close() {
      this.$bvModal.hide("modal-cp-categorias");
    },
    onSalvar(){
        this.carregar()
        this.close()
    },
    editar(id){
        this.id_edit = id
        this.$bvModal.show("modal-cp-categorias")
    },
    async excluir(id){
        this.toastConfirmacao(async ()=>{
            try {
                const result = await ContasPagarCategoriasLib.del(id)
                console.log(result);
            } catch (error) {
                console.error("Erro ao excluir categoria", error);
                this.showToast("error", error.message)
            }
        },()=>{

        },
        )
    },
    async carregar() {
      this.loading = true;
      try {
          this.categorias = await ContasPagarCategoriasLib.get();
      } catch (error) {
          console.error("Erro ao buscar categorias", error);
          this.showToast("error",error)
      }
      this.loading = false;
    },
  },
  async mounted(){
      await this.carregar()
  }
};
</script>

<style></style>
